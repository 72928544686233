import { ExclamationCircleFill } from "react-bootstrap-icons";
import styles from "./input.module.scss";
import classNames from "classnames";

interface textareaProps extends React.HTMLProps<HTMLTextAreaElement> {
  label?: string;
  isDisabled?: boolean;
  registration?: any;
  error?: string;
}

export const TextArea: React.FC<textareaProps> = ({
  placeholder = "",
  isDisabled,
  registration,
  label,
  error,
  ...rest
}) => {
  const inputClass = classNames(
    styles.textarea_field,
    error && styles.input_error
  )
  return (
    <div className="d-flex flex-column">
      {label && <label className={styles.label}>{label}</label>}
      <textarea
        {...registration}
        {...rest}
        disabled={isDisabled}
        className={inputClass}
        placeholder={placeholder}
      />
      {error && <label className={styles.error}><ExclamationCircleFill/>&nbsp;{error}</label>}
    </div>
  );
};
