import { useTranslation } from "react-i18next";

export const AboutUs = () => {
  const { t } = useTranslation(["about_us"]);
  return (
    <div className="container py-5 ws-pre-line d-flex flex-column ">
      <h1>{t("title")}</h1>
      <div>
        <h2>{t("history")}</h2>
        <p>{t("history-description")}</p>
      </div>
      <div className="row gap-2 gap-md-0">
        <div className="col-12 col-md-6 px-md-4 custom-box px-md-5">
          <h2 className="py-md-3 py-1">{t("urban-metro-system")}</h2>
          <p>{t("urban-metro-system-description")}</p>
        </div>
        <div className="col-12 col-md-6 px-md-4 p-0  ">
          <img
            className="rounded"
            src="/assets/smart-clock-arayeh.png"
            alt="smart-clock-arayeh"
            width={"100%"}
            height={"100%"}
            style={{ objectFit: "cover" }}
          />
        </div>
      </div>
      <div className="custom-box my-4 p-md-4">
        <h2>{t("corporate-capabilities")}</h2>
        <p>{t("corporate-capabilities-description")}</p>
      </div>
    </div>
  );
};
