import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Globe } from "react-bootstrap-icons";
import { Dropdown, NavbarUI } from "../../../ui";
import { LANGUAGES } from "../../../locale";
import { NAVBAR_ITEMS } from "../constant";
import { useLanguage } from "../../../hooks/useLanguage";

import styles from "../layout.module.scss";
interface NavbarProps {
  className?: string;
}
export const Navbar: React.FC<NavbarProps> = ({ className }) => {
  const { setLanguage } = useLanguage();
  const { t, i18n } = useTranslation(["landing", "global"]);
  const location=useLocation()
  return (
    <div className={styles.navbar_container}>
      <NavbarUI dir={i18n.dir()} className={className}>
        {NAVBAR_ITEMS.map((item, index) => {
          return (
            <Link
              to={item.href}
              key={index}
              className={`${item.href === location.pathname && styles.active_link} d-flex align-items-center`}
            >
              {t("global:" + item.title)}
            </Link>
          );
        })}
      </NavbarUI>
      <Dropdown
        title={
          <span className="d-flex gap-2 flex-center">
            <strong className=" fs-7">{t(i18n.language)}</strong>
            <Globe />
          </span>
        }
      >
        {LANGUAGES.map((language) => {
          return (
            <button
              onClick={() => setLanguage(language.key)}
              key={language.key}
              className={styles.dropdown_item}
            >
              {t(language.name)}
            </button>
          );
        })}
      </Dropdown>
    </div>
  );
};
