import { useState } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { Input, TextArea, Button } from "../../../ui";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { sendEmail } from "../api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface FormValues {
  email: string;
  subject: string;
  description: string;
}

const formSchema = z.object({
  email: z
    .string()
    .email({ message: "error-email" })
    .min(6, { message: "error-email" }),
  subject: z.string().min(6, { message: "error-min-length" }),
  description: z.string().min(6, { message: "error-min-length" }),
});

export const Form = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const { t } = useTranslation("global");
  const { i18n } = useTranslation();
  const { handleSubmit, register, formState } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  const position = i18n.dir() === "rtl" ? "top-right" : "top-left";

  const submitHandler = async (params: FormValues) => {
    setIsLoading(true);
    setApiError(null);

    try {
      const response = await sendEmail(params);
      console.log(response);
      toast.success("success-email");
    } catch (error) {
      console.error(error);
      setApiError("An error occurred. Please try again.");
      toast.error(t("error-api"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(submitHandler)}
      className="d-flex flex-column gap-2"
    >
      <Input
        error={t(formState.errors.email?.message as string)}
        registration={register("email")}
        className="mb-2"
        label={t("email")}
        placeholder={t("email-placeholder")}
      />
      <Input
        error={t(formState.errors.subject?.message as string)}
        registration={register("subject")}
        label={t("subject")}
        placeholder={t("subject-placeholder")}
      />
      <TextArea
        error={t(formState.errors.description?.message as string)}
        registration={register("description")}
        placeholder={t("textarea-placeholder")}
      />
      <Button variants="primary" isDisabled={isLoading} isLoading={isLoading}>
        {t("submit")}
      </Button>
      <ToastContainer position={position} />
    </form>
  );
};
