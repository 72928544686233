import { EnvelopeFill, GeoAltFill } from "react-bootstrap-icons";
export const NAVBAR_ITEMS = [
  {
    title: "home",
    href: "/",
  },
  {
    title: "about-us",
    href: "/about-us",
  },
  {
    title: "products",
    href: "/products",
  },
  {
    title: "citizen-account-system",
    href: "/citizen-account-system",
  },
];

export const EXTERNAL_LINKS = [
  {
    title: "isfahan",
    href: "https://ica.isfahan.ir/ui/#/",
  },
  {
    title: "arak",
    href: "http://profile.arak.com/",
  },
  {
    title: "shahinshahr",
    href: "http://mobile.shahinshahr.ir/ui/#/",
  },
];

export const CONTACT_US = [
  {
    title: "info@arayehco.com",
    icon: <EnvelopeFill />,
  },
  {
    title: "address",
    icon: <GeoAltFill />,
  },
];
