import { Navbar } from "./navbar";
import { Footer } from "./footer";
import { Outlet } from "react-router-dom";

import styles from "./layout.module.scss";

export const Layout = () => {
  return (
    <div>
      <Navbar />
      <div className={styles.layout_container}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};
