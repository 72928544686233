import landing_en from "./landing/en.json";
import landing_fa from "./landing/fa.json";

import products_en from "./products/en.json";
import products_fa from "./products/fa.json";

import global_en from "./global/en.json";
import global_fa from "./global/fa.json";

import citizen_account_system_en from "./citizen-account-system/en.json";
import citizen_account_system_fa from "./citizen-account-system/fa.json";

import about_us_en from "./about-us/en.json";
import about_us_fa from "./about-us/fa.json";

export const translations = {
  en: {
    landing: landing_en,
    products: products_en,
    global: global_en,
    citizen_account_system: citizen_account_system_en,
    about_us: about_us_en,
  },
  fa: {
    landing: landing_fa,
    global: global_fa,
    products: products_fa,
    citizen_account_system: citizen_account_system_fa,
    about_us: about_us_fa,
  },
};

export const LANGUAGES = [
  {
    name: "English",
    key: "en",
  },
  {
    name: "Persian",
    key: "fa",
  },
];
