import { Link } from "react-router-dom";
import { CaretUpFill, Linkedin, TelephoneFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { NAVBAR_ITEMS, EXTERNAL_LINKS, CONTACT_US } from "../constant";
import { Form } from "./form";

import styles from "./footer.module.scss";

export const Footer = () => {
  const { t } = useTranslation(["global"]);
  return (
    <footer className={styles.footer_container}>
      <CaretUpFill
        className={styles.scroll_top_btn}
        onClick={() => window.scroll(0, 0)}
      />
      <div className="container d-flex flex-column">
        <div className="d-flex flex-md-row flex-column gap-5 justify-content-between">
          <div className="d-flex flex-column">
            <div className="d-flex gap-5 justify-content-between">
              <div>
                <h2> {t("global:arayeh-co")}</h2>
                <div className="d-flex gap-2 flex-column">
                  {NAVBAR_ITEMS.map((item) => (
                    <Link to={item.href}>{t(item.title)}</Link>
                  ))}
                </div>
              </div>
              <div>
                <h2> {t("global:external-links")}</h2>
                <div className="d-flex gap-2 flex-column">
                  {EXTERNAL_LINKS.map((item) => (
                    <Link key={item.href} target="_blanked" to={item.href}>
                      {t(item.title)}
                    </Link>
                  ))}
                </div>
              </div>
              <div>
                <Link to={"https://www.linkedin.com/company/arayeh-co/"}>
                  <Linkedin width={32} height={32} />
                </Link>
              </div>
            </div>
            <div>
              <h2 className="mt-4"> {t("global:contact-us")}</h2>
              <div className="d-flex gap-2 flex-column">
                {CONTACT_US.map((item) => (
                  <div>
                    {item.icon}
                    &nbsp;
                    <span className="fs-7">{t(`global:${item.title}`)}</span>
                  </div>
                ))}
                <div>
                  <TelephoneFill />
                  &nbsp;
                  <a href="tel:02188677586" className="fs-7">
                    {t("global:telephone-number")}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.contact_us_container} col-md-4`}>
            <h2>{t("global:contact-us")}</h2>
            <div className="d-flex gap-2 flex-column">
              <Form />
            </div>
          </div>
        </div>
        <hr />
        <p className="fs-8 text-center">{t("global:credit")}</p>
      </div>
    </footer>
  );
};
