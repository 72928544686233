import { useTranslation } from "react-i18next";

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const setLanguage = (lng: string) => {
    i18n.changeLanguage(lng);

    if (i18n.language === "en") {
      document.documentElement.dir = "ltr";
      document.documentElement.style.setProperty(
        "--app-font-family",
        "mona-sans"
      );
    } else {
      document.documentElement.dir = "rtl";
      document.documentElement.style.setProperty(
        "--app-font-family",
        "shabnam"
      );
    }
  };
  return { setLanguage };
};
