import { ExclamationCircleFill } from "react-bootstrap-icons";
import classNames from "classnames";
import styles from "./input.module.scss";

interface inputProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  isDisabled?: boolean;
  registration?: any;
  error?: string;
}

export const Input: React.FC<inputProps> = ({
  placeholder = "",
  isDisabled,
  label,
  registration,
  className,
  error,
  ...rest
}) => {
  const inputClass = classNames(
    styles.textarea_field,
    error && styles.input_error
  );
  return (
    <div className="d-flex flex-column">
      {label && <label className={styles.label}>{label}</label>}
      <input
        {...registration}
        {...rest}
        disabled={isDisabled}
        className={inputClass}
        placeholder={placeholder}
      />
      {error && (
        <label className={styles.error}>
          <ExclamationCircleFill />
          &nbsp;{error}
        </label>
      )}
    </div>
  );
};
