import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card } from "../../../ui/card";
import { PRODUCTS } from "../constant";
export const Product = () => {
  const { t } = useTranslation(["products"]);
  const { productName } = useParams();
  const product = PRODUCTS.find((item) => item.title === productName);
  if (product) {
    const { src, title, describe, description } = product;
    return (
      <div className="py-3">
        <div className="container p-0 d-flex flex-column gap-3">
          <div className="d-flex flex-column gap-4 custom-box py-4 px-md-5 w-100">
            <div className=" w-100 w-md-50">
              <h1 className="my-md-2">{t(title as string)}</h1>
              <p>{t(describe as string)}</p>
            </div>
            <img className="col col-md-6 " src={src} alt={title} />
            <p>{t(description as string)}</p>
          </div>
          <div className="custom-box  pt-4 pb-5 px-4">
            <h1 className="pb-3">{t("other-products")}</h1>
            <div className="w-100 d-flex gap-3 flex-wrap">
              {PRODUCTS.filter((item) => item.title !== productName).map(
                (item) => {
                  return (
                    <Card
                      key={item.title}
                      {...item}
                      title={t("products:" + item.title)}
                      path={`/products/${item.title}`}
                      isBlur={false}
                    />
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <>not found</>;
  }
};
