import React, { ReactNode, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./navbar.module.scss";
import { useLocation } from "react-router-dom";

interface NavbarProps {
  className?: string;
  children?: ReactNode;
  dir: string;
}
export const Navbar: React.FC<NavbarProps> = ({ className, children, dir }) => {
  const [show, setShow] = useState(false);
  const navbarClass = classNames(styles.navbar_container, className);
  const menuClass = classNames(styles.menu, [!show && styles.none_menu]);
  const shadowClass = classNames(styles.menu_shadow, [
    !show && styles.none_shadow,
  ]);
  const location = useLocation();
  useEffect(() => {
    setShow(false);
  }, [location.pathname]);
  return (
    <div className={navbarClass}>
      <div className={styles.mobile_navbar}>
        <div className={styles.menu_icon_container}>
          <button
            className={`${
              !show ? styles.active : styles.inactive
            } button--striped link-primary`}
            onClick={() => setShow(!show)}
          >
            <div></div> <div></div>
            <div></div>
          </button>
        </div>
        <div className={menuClass} dir={dir}>
          {children}
        </div>
        <div
          dir={dir}
          onClick={() => setShow(false)}
          className={shadowClass}
        ></div>
      </div>
      <div className={styles.window_navbar}>
        <div className="d-flex gap-3">{children}</div>
      </div>
    </div>
  );
};
