import {
  Android2,
  Ubuntu,
  CpuFill,
  Calendar3EventFill,
  PeopleFill,
} from "react-bootstrap-icons";

export const ABILITIES = [
  {
    icon: <Android2 />,
    title: "android",
  },
  {
    icon: <CpuFill />,
    title: "hardware",
  },
  {
    icon: <Calendar3EventFill />,
    title: "web",
  },
  {
    icon: <Ubuntu />,
    title: "embedded-systems-and-Linux-based",
  },
  {
    title: "service-support",
    icon: <PeopleFill />,
  },
];
