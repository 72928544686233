import { useTranslation } from "react-i18next";
 
export const CitizenAccountSystem = () => {
  const { t } = useTranslation(["citizen_account_system"]);

  return (
    <div className={ "container py-5 ws-pre-line"}>
      <div className="row">
        <div className="col-md-6 p-0 p-md-2">
          <h1>{t("title")}</h1>
          <p>{t("description")}</p>
          <br />
          <h1>{t("citizen-account-system-conceptual-framework")}</h1>
          <p>{t("citizen-account-system-conceptual-framework-description")}</p>
        </div>
        <div className="col-md-6 p-0 p-md-2">
          <img
            width={"100%"}
            src="/assets/citizen-account-base.png"
            alt="/assets/citizen-account-base.png"
          />
        </div>
      </div>
      <div className="custom-box p-4">
        <p>{t("account-base-and-qr")}</p>
        <br />
        <h1>{t("benefits-title")}</h1>
        <p>{t("benefits")}</p>
      </div>
    </div>
  );
};
