import React, { useState } from "react";
import styles from "./card.module.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";
interface CardProp {
  title: string;
  color: string;
  path: string;
  src: string;
  className?: string;
  isBlur?: boolean;
  hoverState?:boolean;
}
export const Card: React.FC<CardProp> = ({
  title,
  color,
  path,
  src,
  hoverState,
  className,
  isBlur,
}) => {
  const [isHovered, setHovered] = useState(false);
  const cardClass = classNames(styles.card, [
    !isBlur && styles.disableBlur,
    !hoverState && styles.disableHover,
    className,
  ]);
  return (
    <Link
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      to={path}
      className={cardClass}
      style={{
        background: isHovered
          ? `radial-gradient(circle, #ffffff 0%, ${color} 100%)`
          : `radial-gradient(circle, #8f8f8f 0%, ${color} 100%)`,
      }}
    >
      <span>{title}</span>
      <img src={src} alt={title} />
    </Link>
  );
};
