import classNames from "classnames";
import React, { ReactNode } from "react";
import { Spinner } from "react-bootstrap";

import styles from "./button.module.scss";

interface buttonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isDisabled: boolean;
  isLoading: boolean;
  variants: string;
  children?: ReactNode;
}

export const Button: React.FC<buttonProps> = ({
  isDisabled,
  isLoading,
  variants,
  children,
  ...rest
}) => {
  const buttonClasses = classNames(
    variants === "primary" && styles.btn_classes,
    variants === "secondary" && styles.secondary_btn_classes,
    isDisabled && styles.disabled
  );
  return (
    <div>
      <button className={buttonClasses} disabled={isDisabled} {...rest}>
        {isLoading ? <Spinner size="sm" /> : children}
      </button>
    </div>
  );
};
