import { createBrowserRouter } from "react-router-dom";
import { Layout } from "../features/layouts";
import { Landing } from "../features/landing";
import { Product } from "../features/products/product";
import { AppProvider } from "../features/app-provider";
import { CitizenAccountSystem } from "../features/citizen-account-system";
import { AboutUs } from "../features/about-us";
import { Products } from "../features/products";
export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AppProvider>
        <Layout />
      </AppProvider>
    ),
    errorElement: <div>error</div>,
    children: [
      { path: "/", element: <Landing /> },
      { path: "citizen-account-system", element: <CitizenAccountSystem /> },
      { path: "about-us", element: <AboutUs /> },
      { path: "products/:productName", element: <Product /> },
      { path: "products", element: <Products /> },
    ],
  },
]);
