import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card } from "../../ui/card";
import { ABILITIES } from "./constant";
import { PRODUCTS } from "../products/constant";
import styles from "./landing.module.scss";
export const Landing = () => {
  const { t, i18n } = useTranslation(["landing", "products"]);
  return (
    <div className={styles.landing_container}>
      <div className={styles.landing_background} dir={i18n.dir()}>
        <img
          src={"assets/arayeh-co-background.png"}
          alt="arayehco.com hooshmandtechnology.ir"
          height={"100%"}
        />
      </div>
      <main className={styles.main}>
        <div className={styles.title}>
          <h1>{t("landing:title")}</h1>
          <h2>{t("landing:title-description")}</h2>
        </div>
        <div className="col col-md-6">
          <h2 className="my-2">{t("landing:comprehensive-solutions")}</h2>
          <p>{t("landing:comprehensive-solutions-description")}</p>
        </div>
        <br className="d-none d-md-block" />
        <div
          className={
            "w-100 d-flex justify-content-center  flex-wrap gap-2 gap-md-0"
          }
        >
          {PRODUCTS.map((item) => (
            <Card
              {...item}
              title={t(`products:${item.title}`)}
              path={`products/${item.title}`}
              key={item.title}
            />
          ))}
        </div>
        <br className="d-none d-md-block" />
        <br className="d-none d-md-block" />
        <div className="container d-flex gap-5" dir="rtl">
          <img
            className="w-50 d-none d-md-block"
            src="assets/tms.png"
            alt={t("landing:fleet-management-title")}
          />
          <div className="w-md-50 w-100" dir={i18n.dir()}>
            <h1 className="my-2">{t("landing:fleet-management")}</h1>
            <p>{t("landing:fleet-management-description")}</p>
          </div>
        </div>
        <div className={styles.abilities_container}>
          {ABILITIES.map((item) => {
            return (
              <div className={styles.icon_box_container} key={item.title}>
                <div className={styles.icon_box}>{item.icon}</div>
                <label className="fs-7 fs-md-2 text-center">
                  {t("landing:" + item.title)}
                </label>
              </div>
            );
          })}
        </div>
        <div className="container d-flex flex-column-reverse flex-md-row gap-md-5">
          <div className="col-md-8 col-12">
            <h1 className="my-2">{t("products:smart-driver-console")}</h1>
            <p>{t("products:smart-driver-console-description")}</p>
            <div className="w-100 d-flex flex-row-reverse">
              <button className="primary-btn">
                <Link to={"/products/smart-driver-console"}>
                  {t("landing:more")}
                </Link>
              </button>
            </div>
          </div>
          <div className="col-md-4 col-12">
            <img
              className="w-100"
              src="assets/smart-driver-console.png"
              alt={t("landing:fleet-management")}
            />
          </div>
        </div>
      </main>
    </div>
  );
};