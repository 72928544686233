import axios from "axios";

interface EmailFormData {
  email: string;
  subject: string;
  description: string;
}

export const sendEmail = async (formData: EmailFormData) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL + `/api/send-email`,
      formData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
