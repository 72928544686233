import { ReactNode } from "react";
import {Dropdown  as BootStrapDropDown} from "react-bootstrap";

import styles from "./dropdown.module.scss";
interface DropdownProps {
  children: ReactNode;
  title: string | ReactNode;
}
export const Dropdown: React.FC<DropdownProps> = ({ children, title }) => {
  return (
    <BootStrapDropDown className={styles.dropdown_container} align={"end"} >
      <BootStrapDropDown.Toggle className={styles.dropdown_button}>
        {title}
      </BootStrapDropDown.Toggle>

      <BootStrapDropDown.Menu className={styles.dropdown_menu}>
        <BootStrapDropDown.Item>{children}</BootStrapDropDown.Item>
      </BootStrapDropDown.Menu>
    </BootStrapDropDown>
  );
};
