export const PRODUCTS = [
    {
      src: "/assets/smart-driver-console.png",
      color: "rgb(135 76 109)",
      title: "smart-driver-console",
      describe: "smart-driver-console-describe",
      description: "smart-driver-console-description",
    },
  
    {
      src: "/assets/multi-purpose-console-validator.png",
      color: "rgb(125 112 56)",
      title: "multi-purpose-console-validator",
      describe: "multi-purpose-console-validator-describe",
      description: "multi-purpose-console-validator-description",
    },
  
    {
      src: "/assets/smart-validator-SA-V7.png",
      color: "rgb(88 128 139)",
      title: "smart-validator-SA-V7",
      describe: "smart-validator-SA-V7-describe",
      description: "smart-validator-SA-V7-description",
    },
    {
      src: "/assets/gate-validators.png",
      color: "rgb(91 88 89)",
      title: "gate-validators",
      describe: "gate-validators-describe",
      description: "gate-validators-description",
    },
    {
      src: "/assets/urban-train-data-recorder.png",
      color: "rgb(55 96 160)",
      title: "urban-train-data-recorder",
      describe: "urban-train-data-recorder-describe",
      description: "urban-train-data-recorder-description",
    },
    {
      src: "/assets/smart-validator-subway.png",
      color: "rgb(19 16 17)",
      title: "smart-validator-subway",
      describe: "smart-validator-subway-describe",
      description: "smart-validator-subway-description",
    },
    {
      src: "/assets/centralized-ip-based-clock.png",
      title: "centralized-ip-based-clock",
      describe: "centralized-ip-based-clock-describe",
      description: "centralized-ip-based-clock-description",
      color: "#758293",
    },
  ];
  