import { useState } from "react";
import Slider from "react-slick";
import { CaretLeftFill, CaretRightFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PRODUCTS } from "./constant";

import styles from "./products.module.scss";
export const Products = () => {
  const [activeProduct, setActiveProduct] = useState(PRODUCTS[1]);
  const { t, i18n } = useTranslation(["products"]);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div className="py-5">
      <Slider {...settings} className="rounded mb-5">
        {PRODUCTS.map((item) => (
          <div key={item.title} className={styles.slide_item_container}>
            <Link
              to={item.title}
              className={styles.slide_item}
              style={{
                background: `radial-gradient(circle, #8f8f8f 0%, ${item.color} 100%)`,
              }}
            >
              <img src={item.src} alt={item.title} height={"100%"} />
              <span>{t(item.title)}</span>
            </Link>
          </div>
        ))}
      </Slider>
      <div className="row gap-md-0 gap-3">
        <div className="col-md-4">
          <div className={styles.products_name}>
            {PRODUCTS.map((item) => {
              return (
                <button
                  dir={i18n.dir()}
                  key={item.title}
                  onClick={() => {
                    setActiveProduct(item);
                  }}
                  className={`${
                    item === activeProduct ? styles.active : ""
                  } link button--striped`}
                >
                  {i18n.dir() === "ltr" ? (
                    <CaretRightFill />
                  ) : (
                    <CaretLeftFill />
                  )}
                  <span>{t(item.title)}</span>
                </button>
              );
            })}
          </div>
        </div>
        <div className="col-md-8">
          <div className="d-flex flex-column-reverse flex-md-row gap-md-5  custom-box">
            <div className="col-md-8 col-12">
              <h1 className="my-2">{t("products:" + activeProduct.title)}</h1>
              <p>{t("products:" + activeProduct.title + "-description")}</p>
              <div className="w-100 d-flex flex-row-reverse">
                <button className="primary-btn">
                  <Link to={"/products/" + activeProduct.title}>
                    {t("landing:more")}
                  </Link>
                </button>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <img
                className="w-100"
                src={"assets/" + activeProduct.title + ".png"}
                alt={t(activeProduct.title)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
